import React, {  useState} from 'react';
import { Button, Space, Tooltip, Image } from 'antd';
import { Handle, Position, NodeToolbar } from 'reactflow';
import {NodeEditor} from './NodeEditor';
import * as Icons from '@ant-design/icons';

        const findValueByKey = (obj, key) => {
          if (obj !== undefined && obj !== null) {
            const entries = Object.entries(obj);
            for (const [currentKey, value] of entries) {
              if (currentKey === key) {
                return value;
              }
              if (typeof value === 'object') {
                const nestedValue = findValueByKey(value, key);
                if (nestedValue !== undefined) {
                  return nestedValue;
                }
              }
            }
          }
      
      
          return undefined;
        };

export function BuilderLibrary () {

          const onDragStart = (event, nodeType,payload) => {
                    event.dataTransfer.setData('application/reactflow', nodeType);
                    event.dataTransfer.setData('application/flow-iteractions', JSON.stringify(payload));
                    event.dataTransfer.effectAllowed = 'move';
          };
 
          const elementos = [
                   {id:0,name:"start",payload:{title:'',},label:"",icon: <Icons.RightCircleOutlined />},
                   {id:1,name:"log",payload:{msg:'Mensaje para el log'}, label:"",icon: <Icons.MonitorOutlined />},
                   {id:2,name:"message",payload:{msg:'Mensaje para el usuario'},label:"",icon: <Icons.MessageOutlined />},
                   {id:3,name:"HttpRequest",payload:{fact:"variable",url:'',method:'GET',headers:[{key:'apikey',value:''}],body:[{value:"prueba"}]},label:"",icon:<Icons.GlobalOutlined />},
                   {id:3,name:"HttpRequestSimple",payload:{fact:"variable",url:'',method:'GET',headers:[{key:'apikey',value:''}],body:[{value:"prueba"}]},label:"",icon:<Icons.GlobalOutlined />},
                   {id:4,name:"conditional",payload:{conditions:{"any":[{"fact":"variable","operator":"equal","value":"1"}]}},label:"",icon: <Icons.NodeExpandOutlined />},
                   {id:5,name:"switch",payload:{fact:"variable",options:[{key:'1',value:'Si'},{key:'2',value:'No'}]},label:"",icon:<Icons.ClusterOutlined />},
                   {id:6,name:"inputText",payload:{fact:"variable",msg:'Mensaje para el usuario'},label:"",icon: <Icons.AppstoreOutlined />},
                  // {id:7,name:"inputDate",payload:{fact:null,msg:'Mensaje para el usuario',wait:true,attemps:3,error_msg:'Me temo que no lo entiendo'},label:"",icon: <AppstoreOutlined />},
                  // {id:8,name:"inputNumber",payload:{fact:null,msg:'Mensaje para el usuario',wait:true,attemps:3,error_msg:'Me temo que no lo entiendo'},label:"",icon: <AppstoreOutlined />},
                   {id:9,name:"setVariable",payload:{fact:"variable",value:null},label:"",icon: <Icons.AppstoreOutlined />},
                   {id:9,name:"setAtribute",payload:{fact:"variable",value:null},label:"",icon: <Icons.AppstoreOutlined />},
                   {id:10,name:"optionButton",payload:{fact:"variable",msg:"Opciones",url:'',options:[{key:'1',value:'Si'},{key:'2',value:'No'}]},label:"",icon: <Icons.DatabaseOutlined />},
                   {id:11,name:"optionList",payload:{fact:"variable",msg:"Opciones",options:[{key:'1',value:'Si'},{key:'2',value:'No'}]},label:"",icon: <Icons.BarsOutlined />},
                   {id:12,name:"location",payload:{msg:'',url:'',lng:'',lat:''},label:"",icon: <Icons.PushpinTwoTone />},
                   {id:13,name:"image",payload:{title:'',url:''},label:"",icon: <Icons.AppstoreOutlined />},
                   {id:14,name:"document",payload:{title:'',url:''},label:"",icon: <Icons.AppstoreOutlined />},
                   {id:15,name:"video",payload:{title:'',url:''},label:"",icon: <Icons.AppstoreOutlined />},
                   {id:16,name:"audio",payload:{title:'',url:''},label:"",icon: <Icons.AppstoreOutlined />},
                   {id:17,name:"sticker",payload:{title:'',url:''},label:"",icon: <Icons.AppstoreOutlined />},
                 //  {id:18,name:"goto",payload:{node:null},label:"",icon: <AppstoreOutlined />},
                   {id:19,name:"end",payload:{title:'',},label:"",icon: <Icons.CheckOutlined />}
          ]
          const result = elementos.map((elem,index) => {
                    return (
                        <Button size={'large'} key={index} style= {{width:'100px'}} type="primary" className="dndnode input" onDragStart={(event) => onDragStart(event,elem.name,elem.payload)} draggable>
                          {elem.name}<br></br>
                          {elem.icon}
                        </Button>
                    )
          })
          return (
                    <Space direction="vertical" >
                              {result}
                    </Space>
          )
};
export function BlockConditional({ data, isConnectable }) {
  //const onChange = useCallback((evt) => {
  //  console.log(evt.target.value);
  //}, [])

  const [isFormVisible, setFormVisible] = useState(false);

  const handleNodeEditor = () => {
    setFormVisible(true);
  };

  const handleFormClose = () => {
    setFormVisible(false);
  };

  const handleSave = (updatedData) => {
    delete updatedData.payload.wait
    console.log("VIENE LO MODIFICADO  ",updatedData)
    data.name = updatedData.payload.name ?? updatedData.name
    updatedData.name = data.name
    data.delay =  updatedData.delay
    data.wait =  updatedData.wait

    data.payload = updatedData.payload
    setFormVisible(false);
  };

  return (
    <div className = "block-node"  onDoubleClick={handleNodeEditor}>
      <div className="node-name">{data.name}</div>
      <Tooltip title={'['+data.id +']'}>
            <div className="node-message">
              {findValueByKey(data?.payload?.conditions, 'fact')}
            </div>
      </Tooltip>
      


      <div className="node-type">{data.type} - {data.id}</div>
      {isFormVisible && (
        <NodeEditor
          data={data}
          onClose={handleFormClose}
          onSave={handleSave}
          onUpdate={(updatedData) => {
            setFormVisible(false);
            // Actualizar los datos en el componente BlockSwitch
            // Puedes hacer cualquier manipulación adicional aquí si es necesario
            console.log(updatedData);
          }}
        />
      )}
      <Handle
        type="target"
        id="in"
        className='HandleTarget'
        position={Position.Left}
        style={{ left: '-7px',background: '#555' ,width:'15px' ,height:'15px'}}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        className='HandleSource'
        position={Position.Right}
        id="out"
        style={{ right: '-7px',top: '50%', background: '#00aa00',width:'15px' ,height:'15px' }}
        isConnectable={isConnectable}
      >
                <div key={'detailtrue'} className='HandleSource_name'><strong>{findValueByKey(data?.payload?.conditions, 'value')}</strong>   </div>
                
      </Handle>
      <Handle
        type="source"
        className='HandleSource'
        position={Position.Bottom}
        id="false"
        style={{bottom: '-10px', left:'20%',background: '#aa0000' ,width:'15px' ,height:'15px'}}
        isConnectable={isConnectable}
      />
      <NodeToolbar isVisible={data.toolbarVisible} position={Position.Bottom}>
          <Tooltip title="Settings">
            <Button onClick={handleNodeEditor}  size="small" type="primary" shape="circle" icon={<Icons.SettingOutlined  />} />
          </Tooltip>   
      </NodeToolbar>     

    </div>
  );
}
export function BlockHttpRequest({ data, isConnectable }) {

  const [is_NodeEditor_Visible, setNodeEditor_Visible] = useState(false);

  const handle_NodeEditor = () => {
    setNodeEditor_Visible(true);
  };

  const handle_Close_NodeEditor = () => {
    setNodeEditor_Visible(false);
  };

  const handle_Update_NodeEditor = (updatedData) => {

  }


  const handle_Save_NodeEditor = (updatedData) => {
    delete updatedData.payload.wait
    data.name = updatedData.payload.name ?? updatedData.name
    updatedData.name = data.name
    data.delay =  updatedData.delay
    data.wait =  updatedData.wait
    data.payload = updatedData.payload
    setNodeEditor_Visible(false);
  };
  return (
    <div className = "block-node"  onDoubleClick={handle_NodeEditor}>
      <div className="node-name">{data.name}</div>
      <Tooltip title={'['+data.id +'] ' + data.payload?.method + ' ' + data.payload?.url ?? ''}>
          <div className="node-message">
            <strong>{findValueByKey(data?.payload, 'fact')}</strong>
            <p>{(data.payload?.url.length>38)?data.payload?.url.substring(0,38)+' [...]':data.payload?.url ?? ''}</p>
          </div>
      </Tooltip>
      


      <div className="node-type">{data.type} - {data.id}</div>
      {is_NodeEditor_Visible && (
        <NodeEditor
          data={data}
          onClose={handle_Close_NodeEditor}
          onSave={handle_Save_NodeEditor}
          onUpdate={handle_Update_NodeEditor}
        />
      )}
      <Handle
        type="target"
        id="in"
        className='HandleTarget'
        position={Position.Left}
        style={{ left: '-7px',background: '#555' ,width:'15px' ,height:'15px'}}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        className='HandleSource'
        position={Position.Right}
        id="out"
        style={{ right: '-7px',top: '50%', background: '#00aa00',width:'15px' ,height:'15px' }}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        className='HandleSource'
        position={Position.Bottom}
        id="false"
        style={{bottom: '-9px', left:'20%',background: '#aa0000' ,width:'15px' ,height:'15px'}}
        isConnectable={isConnectable}
      />
      <NodeToolbar isVisible={data.toolbarVisible} position={Position.Bottom}>
          <Tooltip title="Settings">
            <Button onClick={handle_NodeEditor}  size="small" type="primary" shape="circle" icon={<Icons.SettingOutlined  />} />
          </Tooltip>   
      </NodeToolbar>     

    </div>
  );
}
export function BlockHttpRequestSimple({ data, isConnectable }) {
  console.log(data, isConnectable);
  const [is_NodeEditor_Visible, setNodeEditor_Visible] = useState(false);

  const handle_NodeEditor = () => {
    setNodeEditor_Visible(true);
  };

  const handle_Close_NodeEditor = () => {
    setNodeEditor_Visible(false);
  };

  const handle_Update_NodeEditor = (updatedData) => {

  }


  const handle_Save_NodeEditor = (updatedData) => {
    delete updatedData.payload.wait
    data.name = updatedData.payload.name ?? updatedData.name
    updatedData.name = data.name
    data.delay =  updatedData.delay
    data.wait =  updatedData.wait
    data.payload = updatedData.payload
    setNodeEditor_Visible(false);
  };
  return (
    <div className = "block-node"  onDoubleClick={handle_NodeEditor}>
      <div className="node-name">{data.name}</div>
      <Tooltip title={'['+data.id +'] ' + data.payload?.method + ' ' + data.payload?.url ?? ''}>
          <div className="node-message">
            <strong>{findValueByKey(data?.payload, 'fact')}</strong>
            <p>{(data.payload?.url.length>38)?data.payload?.url.substring(0,38)+' [...]':data.payload?.url ?? ''}</p>
          </div>
      </Tooltip>
      


      <div className="node-type">{data.type} - {data.id}</div>
      {is_NodeEditor_Visible && (
        <NodeEditor
          data={data}
          onClose={handle_Close_NodeEditor}
          onSave={handle_Save_NodeEditor}
          onUpdate={handle_Update_NodeEditor}
        />
      )}
      <Handle
        type="target"
        id="in"
        className='HandleTarget'
        position={Position.Left}
        style={{ left: '-7px',background: '#555' ,width:'15px' ,height:'15px'}}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        className='HandleSource'
        position={Position.Right}
        id="out"
        style={{ right: '-7px',top: '50%', background: '#00aa00',width:'15px' ,height:'15px' }}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        className='HandleSource'
        position={Position.Bottom}
        id="false"
        style={{bottom: '-9px', left:'20%',background: '#aa0000' ,width:'15px' ,height:'15px'}}
        isConnectable={isConnectable}
      />
      <NodeToolbar isVisible={data.toolbarVisible} position={Position.Bottom}>
          <Tooltip title="Settings">
            <Button onClick={handle_NodeEditor}  size="small" type="primary" shape="circle" icon={<Icons.SettingOutlined  />} />
          </Tooltip>   
      </NodeToolbar>     

    </div>
  );
}
export function BlockGeneric({ data, isConnectable }) {
  const [isFormVisible, setFormVisible] = useState(false);
  const handleNodeEditor = () => {
    setFormVisible(true);
  };

  const handleFormClose = () => {
    setFormVisible(false);
  };

  const handleSave = (updatedData) => {
    delete updatedData.payload.wait
    console.log("BlockGeneric ",updatedData)
    data.name = updatedData.payload.name ?? updatedData.name
    updatedData.name = data.name
    data.delay =  updatedData.delay
    data.wait =  updatedData.wait
    data.payload = updatedData.payload
    setFormVisible(false);
  };

  return (
    <div className = "block-node"  onDoubleClick={handleNodeEditor}>
      <div className="node-name">{data.name}</div>     
      <div className="node-type">{data.type} - {data.id}</div>
      {isFormVisible && (
        <NodeEditor
          data={data}
          onClose={handleFormClose}
          onSave={handleSave}
          onUpdate={(updatedData) => {
            setFormVisible(false);
          }}
        />
      )}
      <Handle
        type="source"
        id="out"
        className='HandleSource'
        position={Position.Right}
        style={{right:'-7px'}}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      >
      </Handle>       
      <Handle
        type="target"
        className='HandleTarget'
        position={Position.Left}
        id="in"
        style={{left:'-7px'}}
        isConnectable={isConnectable}
      />
      <NodeToolbar isVisible={data.toolbarVisible} position={Position.Bottom}>
          <Tooltip title="Settings">
              <Button onClick={handleNodeEditor}  size="small" type="primary" shape="circle" icon={<Icons.SettingOutlined  />} />
          </Tooltip>   
      </NodeToolbar>         
    </div>
  );
}
export function BlockOption({ data, isConnectable }) {
  const [outputCount,setoutputCount] = useState((data.payload?.options).includes("$")? 0 : data.payload?.options?.length);
  const nodeHeight =25 + outputCount * 30;
  
 
  const limite = (data.type==="optionButton")?3:(data.type==="optionList")?10:20 
  const [isFormVisible, setFormVisible] = useState(false);
  const handleNodeEditor = () => {
    setFormVisible(true);
  };

  const handleFormClose = () => {
    setFormVisible(false);
  };

  const handleUpdate = (updatedData) => {

  }

  const handleSave = (updatedData) => {
    delete updatedData.payload.wait
    if (updatedData.payload.options.includes("$") ) {
      data.payload.options =updatedData.options
      setoutputCount(0)
    } else {
      if (updatedData.payload.options.length >= limite ) {
        updatedData.payload.options = updatedData.payload.options.slice(0, limite)
        data.payload.options = data.payload.options.slice(0, limite)
        setoutputCount(updatedData.payload.options.length)
      }
    }

    if (updatedData.type !=="switch") {
          updatedData.wait =true // provocado por el tipo de nodo
    } else {
          updatedData.wait =false // provocado por el tipo de nodo
    }

    data.name = updatedData.payload.name ?? updatedData.name
    updatedData.name = data.name
    data.delay =  updatedData.delay
    data.wait =  updatedData.wait
    data.payload = updatedData.payload
    setFormVisible(false);
  };

  const getMaxKey = (data) => {
    let maxKey = 0;
    data.forEach((obj) => {
      if (!isNaN(obj.key)) {
        const key = parseInt(obj.key, 10);
        maxKey = Math.max(maxKey, key)+1;
      } else {
        const keyNumber = parseInt(maxKey, 10);
        maxKey = isNaN(keyNumber) ? 0 : keyNumber + 1;
      }
    });
    return "" + maxKey;
  };

  const increase= () => {
    let aux = getMaxKey(data.payload.options)
    data.payload.options.push({"key":aux,"value":"New value"})
    if (outputCount<limite) setoutputCount(outputCount+1)
  }
  const decrease= () => {
    data.payload.options.pop();
    if (outputCount>1)  setoutputCount(outputCount-1)
  }

  return (
    <div className = "block-node"  onDoubleClick={handleNodeEditor}  style={{ height: nodeHeight?nodeHeight:250 }}>
      <div className="node-name">{data.name}</div>
      <Tooltip title={'['+data.id +'] ' + data.payload?.msg ?? ''}>
          <div className="node-message">{data.payload?.msg ?? ''}</div>
      </Tooltip>    
      <div className="node-type">{data.type} - {data.id}</div>
      {isFormVisible && (
        <NodeEditor
          data={data}
          onClose={handleFormClose}
          onSave={handleSave}
          onUpdate={handleUpdate}
        />
      )}

{Array(outputCount)
        .fill(null)
        .map((_, i) => (
          <div key={'map'+i}  >
                <Handle 
                  type="source"
                  className='HandleSource'
                  id={`out-${i+1}`}
                  position={Position.Right}
                  
                  style={{top: 25 + i * 30 ,right:'-7px', background: (data.type==="optionButton")?'#5C4E8C':(data.type==="optionList")?'#7C4394':'#BD6E06'   }}
                  onConnect={(params) => console.log('handle onConnect', params)}
                  isConnectable={isConnectable}      
              >
                 <div key={'detail'+i} className='HandleSource_name'>{data.payload?.options[i]?.value}    </div>
                </Handle> 
             
          </div>
        ))}



      <Handle
        type="source"
        className='HandleSource'
        id="out"
        key={'sourcedefault'} 
        position={Position.Bottom}
        style={{bottom:'-8px'}}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />   
   
      <Handle
        type="target"
        className='HandleTarget'
        position={Position.Left}
        id="in"
        style={{left:'-7px'}}
        isConnectable={isConnectable}
      />

      {!(data.payload?.options).includes("$") && 
      <div className="node-actions">
          <Button className="btn-secondary" disabled={outputCount ===limite} onClick={increase} size="small" type="primary" shape="circle" ><Icons.PlusOutlined /></Button>
          <Button className="btn-secondary" disabled={outputCount ===1} onClick={decrease} size="small" type="primary" shape="circle"  ><Icons.MinusOutlined /></Button>
      </div>      
      }


      <NodeToolbar isVisible={data.toolbarVisible} position={Position.Bottom}>
          <Tooltip title="Settings">
              <Button onClick={handleNodeEditor}  size="small" type="primary" shape="circle" icon={<Icons.SettingOutlined  />} />
          </Tooltip>   
      </NodeToolbar>         
    </div>
  );
}
export function BlockinputText({ data, isConnectable }) {
  const [isFormVisible, setFormVisible] = useState(false);
  const handleNodeEditor = () => {
    setFormVisible(true);
  };

  const handleFormClose = () => {
    setFormVisible(false);
  };

  const handleSave = (updatedData) => {
    delete updatedData.payload.wait
    updatedData.wait =true // provocado por el tipo de nodo
    data.name = updatedData.payload.name ?? updatedData.name
    updatedData.name = data.name
    data.delay =  updatedData.delay
    data.wait =  updatedData.wait
    data.payload = updatedData.payload
    setFormVisible(false);
  };

  return (
    <div className = "block-node"  onDoubleClick={handleNodeEditor}>
      <div className="node-name">{data.name}</div>
      <Tooltip title={'['+data.id +'] ' + data.payload?.msg ?? ''}>
          <div className="node-message">{data.payload?.msg ?? ''}</div>
      </Tooltip>
      <div className="node-type">{data.type} - {data.id}</div>
      {isFormVisible && (
        <NodeEditor
          data={data}
          onClose={handleFormClose}
          onSave={handleSave}
          onUpdate={(updatedData) => {
            setFormVisible(false);
          }}
        />
      )}
      <Handle
        type="source"
        id="out"
        className='HandleSource'
        position={Position.Right}
        style={{right:'-7px'}}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      >
      </Handle>       
      <Handle
        type="target"
        className='HandleTarget'
        position={Position.Left}
        id="in"
        style={{left:'-7px'}}
        isConnectable={isConnectable}
      />
      <NodeToolbar isVisible={data.toolbarVisible} position={Position.Bottom}>
          <Tooltip title="Settings">
              <Button onClick={handleNodeEditor}  size="small" type="primary" shape="circle" icon={<Icons.SettingOutlined  />} />
          </Tooltip>   
      </NodeToolbar>         
    </div>
  );
}
export function BlockMessage({ data, isConnectable }) {
  const [isFormVisible, setFormVisible] = useState(false);
  const handleNodeEditor = () => {
    setFormVisible(true);
  };

  const handleFormClose = () => {
    setFormVisible(false);
  };

  const handleSave = (updatedData) => {
    delete updatedData.payload.wait
    data.name = updatedData.payload.name ?? updatedData.name
    updatedData.name = data.name
    data.delay =  updatedData.delay
    data.wait =  updatedData.wait
    data.payload = updatedData.payload
    setFormVisible(false);
  };

  return (
    <div className = "block-node"  onDoubleClick={handleNodeEditor}>
      <Tooltip title={'['+data.id +'] ' + data.payload?.msg ?? ''}>
          <div className="node-message">{data.payload?.msg ?? ''}</div>
      </Tooltip>
      
      <div className="node-type">{data.type} - {data.id}</div>
      {isFormVisible && (
        <NodeEditor
          data={data}
          onClose={handleFormClose}
          onSave={handleSave}
          onUpdate={(updatedData) => {
            setFormVisible(false);
          }}
        />
      )}
      <Handle
        type="source"
        id="out"
        className='HandleSource'
        position={Position.Right}
        style={{right:'-7px'}}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      >
      </Handle>       
      <Handle
        type="target"
        className='HandleTarget'
        position={Position.Left}
        id="in"
        style={{left:'-7px'}}
        isConnectable={isConnectable}
      />
      <NodeToolbar isVisible={data.toolbarVisible} position={Position.Bottom}>
          <Tooltip title="Settings">
              <Button onClick={handleNodeEditor}  size="small" type="primary" shape="circle" icon={<Icons.SettingOutlined  />} />
          </Tooltip>   
      </NodeToolbar>         
    </div>
  );
}
export function BlockImage({ data, isConnectable }) {
  const [isFormVisible, setFormVisible] = useState(false);
  const handleNodeEditor = () => {
    setFormVisible(true);
  };

  const handleFormClose = () => {
    setFormVisible(false);
  };

  const handleSave = (updatedData) => {
    delete updatedData.payload.wait
    console.log("BlockGeneric ",updatedData)
    data.name = updatedData.payload.name ?? updatedData.name
    updatedData.name = data.name
    data.delay =  updatedData.delay
    data.wait =  updatedData.wait
    data.payload = updatedData.payload
    setFormVisible(false);
  };

  return (
    <div className = "block-node"  onDoubleClick={handleNodeEditor}>
      <div className="node-message">
          <Image id={`id_${data.id}`} name={`node_image_${data.id}`} key= {`key_image_${data.id}`} src={data.payload.url}></Image>
      </div>
      <div className="node-type">{data.type}</div>
      {isFormVisible && (
        <NodeEditor
          data={data}
          onClose={handleFormClose}
          onSave={handleSave}
          onUpdate={(updatedData) => {
            setFormVisible(false);
          }}
        />
      )}
      <Handle
        type="source"
        id="out"
        className='HandleSource'
        position={Position.Right}
        style={{right:'-7px'}}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      >
      </Handle>       
      <Handle
        type="target"
        className='HandleTarget'
        position={Position.Left}
        id="in"
        style={{left:'-7px'}}
        isConnectable={isConnectable}
      />
      <NodeToolbar isVisible={data.toolbarVisible} position={Position.Bottom}>
          <Tooltip title="Settings">
              <Button onClick={handleNodeEditor}  size="small" type="primary" shape="circle" icon={<Icons.SettingOutlined  />} />
          </Tooltip>   
      </NodeToolbar>         
    </div>
  );
}
export function BlockStart({ data, isConnectable }) {
   //       const onChange = useCallback((evt) => {
   //         console.log("ON CHAGE" , evt.target.value);
   //       }, []);
          return (

            <div className = "block-node">
              <Handle
                type="source"
                id="out"
                className='HandleSource'
                position={Position.Right}
                style={{right:'-7px'}}
                onConnect={(params) => {
                  params.style = {strokeWidth:2,stroke:"#FF0072"}
                  console.log('handle onConnect', params)
                }}
                isConnectable={isConnectable}
              />
                  <div className="node-name">{data.name}</div>
                  <div className="node-type">{data.type}</div>             
            </div>
          );
}
export function BlockEnd({ data, isConnectable }) {
  //const onChange = useCallback((evt) => {
  //  console.log(evt.target.value);
  //}, []);

  return (
    <div className = "block-node">
      <Handle
        type="target"
        className='HandleTarget'
        position={Position.Left}
        id="in"
        style={{left:'-7px', background: '#00f',width:'15px' ,height:'15px'}}
        isConnectable={isConnectable}
      />
       <div className="node-name">{data.name}</div>
      <div className="node-type">{data.type}</div>     
    </div>
  );
}
export function ConnectionLine ({
  fromX,
  fromY,
  fromPosition,
  toX,
  toY,
  toPosition,
  connectionLineType,
  connectionLineStyle,
}) {
  return (
    <g>
      <path
        fill="none"
        stroke="#222"
        strokeWidth={1.5}
        className="animated"
        d={`M${fromX},${fromY} C ${fromX} ${toY} ${fromX} ${toY} ${toX},${toY}`}
      />
      <circle cx={toX} cy={toY} fill="#fff" r={3} stroke="#222" strokeWidth={1.5} />
    </g>
  );
};